import { isEmpty, isNullish, isPlainObject } from 'remeda';

import type { Theme } from '../contexts/theme.js';
import type {
  BreakpointObject,
  ConditionalVariant,
  CSSVarFunction,
} from '../types.js';

export function joinClassnames(classNames: (string | undefined | null)[]) {
  return classNames.filter(Boolean).join(' ');
}

export function logUnknownProps(
  props: Record<string, unknown>,
  componentName?: string,
) {
  if (isEmpty(props)) return;

  console.warn(
    `The following props are not handled by "rainbowSprinkles()"${
      componentName ? 'component: ' + componentName : ''
    }:`,
    props,
  );
}

function toStringOrUndefined(x: unknown) {
  return (
    isNullish(x) ? undefined
    : typeof x === 'string' ? x
    : `${x}`
  );
}

export function getResponsiveVar<
  T extends string | number | BreakpointObject<unknown> | undefined,
>(
  responsiveVars: T,
  varMap: BreakpointObject<CSSVarFunction>,
): Record<string, string | null | undefined> {
  if (!responsiveVars) return {};

  const isPrimitive =
    typeof responsiveVars === 'string' || typeof responsiveVars === 'number';

  return {
    [varMap.default]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.default,
    ),
    [varMap.xsmall]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.xsmall,
    ),
    [varMap.small]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.small,
    ),
    [varMap.shmedium]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.shmedium,
    ),
    [varMap.medium]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.medium,
    ),
    [varMap.large]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.large,
    ),
    [varMap.xlarge]: toStringOrUndefined(
      isPrimitive ? responsiveVars : responsiveVars.xlarge,
    ),
  };
}

export function variantsToDataAttrs(variantProps: object) {
  const dataAttrs: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(variantProps)) {
    dataAttrs[`data-variant-${key}`] = value || undefined;
  }

  return dataAttrs;
}

export type ThemeVariant<Values> = ConditionalVariant<Theme, Values>;

export function getThemeVariant<T>(
  variantProp: ThemeVariant<T>,
  condition: Theme,
) {
  return isPlainObject(variantProp) ? variantProp[condition] : variantProp;
}
